'use client'

import { FloatButton } from 'antd'
import { ThemeProvider } from 'styled-components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import VLibras from 'vlibras-nextjs'

import { GlobalStyles } from '@/styles/global'

import { useAppAccessibility } from '@/context/accessibilityContext'
import { AuthContextProvider } from '@/context/authContext'

import { CookieBanner } from '@/components/CookieBanner'

import theme from '@/styles/theme'

import * as S from './styles'

import 'cegov-antd/dist/index.css'

const queryClient = new QueryClient()

export function RootLayoutCustom({ children }: { children: React.ReactNode }) {
  const { lengthText } = useAppAccessibility()

  return (
    <>
      <AuthContextProvider>
        <S.VLibrasContainer>
          <VLibras forceOnload />
        </S.VLibrasContainer>
        <FloatButton.BackTop
          style={{
            top: '90%',
            zIndex: 10000
          }}
        />
        <ThemeProvider theme={theme}>
          <GlobalStyles lengthText={lengthText} />
          <QueryClientProvider client={queryClient}>
            {children}
          </QueryClientProvider>

          <CookieBanner />
        </ThemeProvider>
      </AuthContextProvider>
    </>
  )
}
