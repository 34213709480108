function fileListFrom(files: File[]): FileList {
  const b = new ClipboardEvent('').clipboardData || new DataTransfer()
  for (const file of files) b.items.add(file)
  return b.files
}

function dataURLtoFile(dataurl: string, filename: string): File | null {
  if (!dataurl || !dataurl.includes(',')) {
    console.error('String base64 inválida ou vazia.')
    return null
  }

  try {
    const [mimeInfo, base64DataRaw] = dataurl.split(',')
    const mimeMatch = mimeInfo.match(/:(.*?);/)

    if (!mimeMatch || !base64DataRaw) {
      console.error('Formato MIME ou dados base64 ausentes.')
      return null
    }

    const mime = mimeMatch[1]
    const base64Data = base64DataRaw.replace(/[^A-Za-z0-9+/=]/g, '')

    const bstr = atob(base64Data)
    const u8arr = new Uint8Array(bstr.length)
    for (let i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i)
    }

    return new File([u8arr], filename, { type: mime })
  } catch (error) {
    console.error('Erro ao converter dataURL para File:', error)
    return null
  }
}

const concatFileList = (
  newValueImg: FileList | undefined,
  oldValue: FileList | undefined
) => {
  if (newValueImg === undefined && oldValue === undefined)
    return fileListFrom([])

  const fileArrayNew = Array.from(newValueImg || [])
  const fileArrayOld = Array.from(oldValue || [])

  const newImagesFilePrev: File[] = fileArrayOld.concat(fileArrayNew)
  const limitFiles = newImagesFilePrev.slice(0, 10)

  const newFileList = fileListFrom(limitFiles)

  return newFileList
}

const transformAndDeleteAtIndex = (
  fileList: FileList | undefined,
  indexToDelete: number
) => {
  if (
    !fileList ||
    fileList.length === 0 ||
    indexToDelete < 0 ||
    indexToDelete >= fileList.length ||
    (fileList?.length === 1 && indexToDelete === 0)
  ) {
    return fileListFrom([])
  }

  const fileArray = Array.from(fileList)

  fileArray.splice(indexToDelete, 1)

  const newFileList = fileListFrom(fileArray)

  return newFileList
}

function generateStrongPassword(length: number) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+'
  let password = ''
  for (let i = 0; i < length; i++) {
    const index = Math.floor(Math.random() * characters.length)
    password += characters.charAt(index)
  }
  return password
}

function isEmptyObject(object: object) {
  if (object === null || object === undefined) return true
  return Object.keys(object).length === 0
}
export {
  transformAndDeleteAtIndex,
  concatFileList,
  dataURLtoFile,
  fileListFrom,
  generateStrongPassword,
  isEmptyObject
}
