'use client'
import { useEffect, useState } from 'react'

import { ButtonUI } from '../UI/ButtonUI'
import * as S from './styles'

export function CookieBanner() {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent')
    if (!cookieConsent) {
      setIsVisible(true)
    }
  }, [])

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true')
    setIsVisible(false)
  }

  if (!isVisible) {
    return null
  }

  return (
    <S.CookieBannerContainer>
      <div className="container">
        <S.CookieBannerWrapper>
          <S.IconWrapper>
            <S.StyledCookieIcon />
          </S.IconWrapper>
          <S.TextWrapper>
            Nós usamos <i style={{ fontStyle: 'italic' }}>cookies</i> para
            melhorar sua experiência de navegação. Ao utilizar o site, você
            concorda com a política de monitoramento de
            <i style={{ fontStyle: 'italic' }}> cookies</i>. Se você concorda,
            clique no botão ACEITO. Para ter mais informações sobre como isso é
            feito, acesse nosso Aviso de Privacidade.
          </S.TextWrapper>
          <ButtonUI buttonType="primary" size="medium" onClick={handleAccept}>
            ACEITO
          </ButtonUI>
        </S.CookieBannerWrapper>
      </div>
    </S.CookieBannerContainer>
  )
}
