import(/* webpackMode: "eager", webpackExports: ["ConfigProvider"] */ "__barrel_optimize__?names=ConfigProvider!=!/app/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Kanit\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"],\"subsets\":[\"latin\"],\"variable\":\"--font-kanit\",\"display\":\"swap\"}],\"variableName\":\"kanit\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Nunito_Sans\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"],\"subsets\":[\"latin\"],\"variable\":\"--font-nunito\",\"display\":\"swap\"}],\"variableName\":\"nunito\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/lib/registry_styled_components.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppAccessibilityProvider"] */ "/app/src/context/accessibilityContext/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RootLayoutCustom"] */ "/app/src/layouts/RootLayoutCustom/index.tsx");
