'use client'

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from 'react'
import { useRouter, useSearchParams } from 'next/navigation'
import Cookies from 'js-cookie'

import {
  KEY_STORAGE_COORDENADORIA,
  KEY_CACHE_PROFILE_USER,
  KEY_STORAGE_FIRST_NAME,
  profilesServiceRoutes,
  TypeRolesPermisson,
  profilesPermisson,
  KEY_STORAGE_USER,
  KEY_CACHE_TOKEN
} from '../../constants/user-profiles-enums'

import { isEmptyObject } from '@/utils/object'

import { addToStorage } from '@/services/StorageService'
import { apiPlataformaIndicadores } from '@/services/api'

import { TypeApiUserAcesso } from '@/@types/autenticacao'
import { isCadastroAtivo } from '@/services/UserService'
import { Modal } from 'antd'

interface AuthContextProps {
  handleLogin: () => Promise<void>
  handleLogout: () => void
}

interface AuthContextProviderProps {
  children: React.ReactNode
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps)

function AuthContextProvider({ children }: AuthContextProviderProps) {
  const { push } = useRouter()

  const handleLogin = useCallback(async () => {
    return push(
      `${process.env.NEXT_PUBLIC_LOGIN_WEB_APP}?app-key=${process.env.NEXT_PUBLIC_APP_KEY}`
    )
  }, [push])

  const handleLogout = useCallback(() => {
    Cookies.remove(KEY_CACHE_TOKEN)
    Cookies.remove(KEY_CACHE_PROFILE_USER)

    localStorage.removeItem(KEY_STORAGE_USER)
    localStorage.removeItem(KEY_STORAGE_COORDENADORIA)
    localStorage.removeItem(KEY_STORAGE_FIRST_NAME)
  }, [])

  const valuesProvider = useMemo(() => {
    return {
      handleLogin,
      handleLogout
    }
  }, [handleLogin, handleLogout])

  return (
    <AuthContext.Provider value={valuesProvider}>
      {children}
    </AuthContext.Provider>
  )
}

function useAuthentication() {
  const context = useContext(AuthContext)

  if (isEmptyObject(context)) {
    throw new Error(
      'useAuthentication must be used within a AuthContextProvider'
    )
  }

  return context
}

function useFetchAndVerifyAcessoCidadao() {
  const searchParams = useSearchParams()
  const { replace } = useRouter()
  const { handleLogout } = useAuthentication()

  useEffect(() => {
    const fetchAndVerifyApiAcessoCidadao = async () => {
      const tokenUrl = searchParams.get('token')
      const tokenCache = Cookies.get(KEY_CACHE_TOKEN)

      if (tokenCache) {
        const idProfile = Cookies.get(KEY_CACHE_PROFILE_USER)

        if (idProfile !== undefined)
          return replace(`/${profilesServiceRoutes[JSON.parse(idProfile)]}`)
      }

      Cookies.set(KEY_CACHE_TOKEN, JSON.stringify(tokenUrl))

      if (tokenUrl) {
        try {
          const response =
            await apiPlataformaIndicadores.post<TypeApiUserAcesso>(
              `acesso?token=${tokenUrl}`
            )

          const getUser = response.data

          if (!getUser.usuario.cpfCnpj) {
            console.error('CPF/CNPJ indefinido no objeto user.')
            return replace('/error')
          }

          const cadastroAtivo = await isCadastroAtivo(getUser.usuario.cpfCnpj)
          if (cadastroAtivo === true) {
            addToStorage(KEY_STORAGE_USER, JSON.stringify(response.data))
          } else {
            Modal.error({
              title: 'Atenção, seu acesso está desativado',
              content: 'Solicite suporte aos administradores do sistema'
            })
            return replace('/error')
          }

          const firstRole = getUser.usuario.perfilList[0]
          const numberRole = profilesPermisson[firstRole as TypeRolesPermisson]

          Cookies.set(KEY_CACHE_PROFILE_USER, JSON.stringify(numberRole))

          if (numberRole === 2 && !getUser.usuario.flagAtivo) {
            return replace('/dashboards/admin-coordenadoria')
          }

          const confirmProfile = !getUser.flagRegistroCompleto
            ? '/confirmar-perfil'
            : ''
          const serviceRoute = profilesServiceRoutes[numberRole]

          return replace(`/${serviceRoute}${confirmProfile}`)
        } catch (error) {
          console.error('Erro ao processar solicitação à API:', error)
        }
      }

      return replace('/')
    }

    fetchAndVerifyApiAcessoCidadao()
  }, [handleLogout, replace, searchParams])
}

export {
  AuthContextProvider,
  useAuthentication,
  useFetchAndVerifyAcessoCidadao
}
