import styled from 'styled-components'
import { MdOutlineCookie } from 'react-icons/md'

export const CookieBannerContainer = styled.div`
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
  > div.container {
    position: relative;
    width: 100%;
    max-width: 1140px;
  }
`
export const CookieBannerWrapper = styled.div`
  z-index: 100;
  position: fixed;
  bottom: 16px;
  width: 100%;
  max-width: 1140px;
  background-color: ${({ theme }) => theme.colors.tertiary};
  color: white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.colors.primary};
`

export const TextWrapper = styled.div`
  /* display: flex; */
  align-items: center;
  flex-grow: 1;
  margin-left: 1em;
  color: #ffff;
  font-weight: 400;
  flex: 1;
  line-height: 24px;
  size: 1.6rem;
  font-family: 'Raleway', sans-serif;
  margin: 10px;
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`

export const StyledCookieIcon = styled(MdOutlineCookie)`
  color: #ffff;
  height: 34px;
  width: 34px;
`

export const Button = styled.button`
  background: white;
  color: ${({ theme }) => theme.colorInfo};
  border: none;
  border-radius: 5px;
  padding: 0.5em 1em;
  cursor: pointer;
  margin-left: 14px;

  &:hover {
    background: #f1f1f1;
  }
`
