export default {
  border: {
    radius: '0.4rem'
  },
  font: {
    primary:
      "var(--font-kanit), -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    secondary:
      "var(--font-nunito), -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    light: 300,
    normal: 400,
    semiBold: 500,
    bold: 600,
    extraBold: 700,
    bolder: 800,
    sizes: {
      xsmall: '1.2rem',
      small: '1.4rem',
      medium: '1.6rem',
      large: '1.8rem',
      xlarge: '2.4rem',
      xxlarge: '4.8rem',
      xxxlarge: '5.8rem'
    }
  },
  colors: {
    // Properties
    // Primary
    primary: '#26a737',
    primary100: '#d8e6d8',
    primary200: '#bdd9bd',
    primaryBright: '#46b351',
    tag: '#f0faeb',
    // Neutral
    gray: '#8c989e',
    white: '#ffffff',
    // Azul
    secondary: '#407a9d',
    tertiary: '#052e2a',
    // Vermelho
    light: '#fee9ea',
    lightHover: '#fedee0',
    lightActive: '#fcbabe',
    danger: '#f5222d',
    dangerHover: '#dd1f29',
    dangerActive: '#c41b24',
    dark: '#b81a22',
    darkHover: '#93141b',
    darkActive: '#6e0f14',
    darker: '#560c10',
    // Amarelo
    attention: '#ffca16',
    // Soltos
    // Cores de layout
    black: '#000000',
    label: '#2D4040',
    inputDesabilitado: '#495057',
    placeholder: '#8C989E',
    linha: '#8C989E',
    cardFormulario: '#F2F2F2',
    background: '#F0F2F5',
    backgroundSecondary: '#87C878',
    // Cores de status
    sucess: '#1AC931'
  },
  gradients: {
    background: `linear-gradient(90deg, rgba(38,167,55,1) 0%, rgba(41,161,142,1) 100%)`
  },
  spacings: {
    normal: '1.8rem',
    xxsmall: '0.8rem',
    xsmall: '1.6rem',
    small: '2.4rem',
    medium: '3.2rem',
    large: '4rem',
    xlarge: '4.5rem',
    xxlarge: '4.8rem',
    xxxlarge: '5.6rem'
  },
  breakpoints: {
    xsm: '@media screen and (max-width: 340px)',
    sm: '@media screen and (max-width: 600px)',
    md: '@media screen and (max-width: 768px)',
    lg: '@media screen and (max-width: 992px)',
    xl: '@media screen and (max-width: 1200px)'
  },
  zIndex: {
    modal: 1010,
    overlay: 900,
    dropdown: 800
  }
} as const
